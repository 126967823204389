import { Popover, Transition } from "@headlessui/react";
import CartImage from "assets/cart.svg";
import { EmptyCart } from "components/layout/emptyCart";
import Button from "components/common/button/button";
import { Cart, CartItem } from "models/cartItem";
import { Fragment, useContext, useEffect, useState } from "react";
import { routes } from "routes/routes";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context/appContext";
import { updateBasket } from "api/updateBasket";
import { ProductCard } from "../pages/checkout/components/productCard";
import { deleteItem as deleteItemUtil } from "utils/cartUtils";
import { useAuth } from "context/authContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import { loadCartFromLocalStorage } from "hooks/useCart";

export const CartPopup = () => {
  const navigate = useNavigate();
  const [localCart] = useLocalStorage("cart");
  const { cart, setCart } = useContext(AppContext);
  const [invalidCart, setInvalidCart] = useState(false);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn && localCart) {
      setCart(localCart);
    }
  }, [localCart, isLoggedIn, setCart]);

  useEffect(() => {
    if (
      cart?.basketItems?.some(
        (item: CartItem) =>
          item.quantity === 0 || item.quantity.toString() === ""
      )
    ) {
      setInvalidCart(true);
    }
  }, [cart?.basketItems]);

  const updateCartCount = async (item: CartItem) => {
    if (isLoggedIn) {
      try {
        await updateBasket({ itemId: item.id, quantity: item.quantity });
      } catch (error) {
        console.error("Error updating item in cart:", error);
      }
    } else {
      const localCart: Partial<Cart> = loadCartFromLocalStorage();
      const updatedItems = localCart.basketItems?.map((x) =>
        x.productId === item.productId && x.unitFaceValue === item.unitFaceValue
          ? { ...x, quantity: item.quantity }
          : x
      );
  
      const updatedCart: Partial<Cart> = {
        ...localCart,
        basketItems: updatedItems,
        totalItemQuantity: updatedItems?.reduce(
          (sum, x) => sum + x.quantity,
          0
        ),
        totalPrice: updatedItems?.reduce(
          (sum, x) => sum + x.unitPrice * x.quantity,
          0
        ),
      };
  
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCart(updatedCart as Cart);
    }
  };
  

  const onChangeCount = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: CartItem
  ) => {
    if (cart && cart?.basketItems?.length > 0) {
      const selectedItem = cart.basketItems.find(
        (x: CartItem) =>
          x.id === item.id && x.unitFaceValue === item.unitFaceValue
      );
      const value = event.target.value;

      if (selectedItem) {
        if (value === "" || !isNaN(+value)) {
          setCart({
            ...cart,
            basketItems: cart.basketItems.map((x: CartItem) =>
              x.id === item.id && x.unitFaceValue === item.unitFaceValue
                ? { ...selectedItem, quantity: value ? +value : "" }
                : x
            ),
          });

          if (value) {
            setInvalidCart(false);
            updateCartCount({ ...selectedItem, quantity: +value });
          } else setInvalidCart(true);
        }
      }
    }
  };

  const handleDeleteItem = (item: CartItem) => {
    if (isLoggedIn) {
      deleteItemUtil(item, setCart);
    } else {
      const localCart: Partial<Cart> = loadCartFromLocalStorage();
      const updatedItems = localCart.basketItems?.filter(
        (x) =>
          !(x.productId === item.productId && x.unitFaceValue === item.unitFaceValue)
      );
  
      const updatedCart: Partial<Cart> = {
        ...localCart,
        basketItems: updatedItems,
        totalItemQuantity: updatedItems?.reduce(
          (sum, x) => sum + x.quantity,
          0
        ),
        totalPrice: updatedItems?.reduce(
          (sum, x) => sum + x.unitPrice * x.quantity,
          0
        ),
      };
  
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCart(updatedCart as Cart);
    }
  };
  

  const navigateToCheckout = () => navigate(routes.checkout);
  const navigateToHome = () => navigate(routes.index);

  const calculateTotalQuantity = (cart: Cart | undefined): number => {
    return (
      cart?.basketItems?.reduce((acc, item) => acc + item.quantity, 0) ?? 0
    );
  };

  const totalCartCount = calculateTotalQuantity(cart);

  return (
    <Popover>
      <Popover.Button className="focus:outline-none relative">
        <img
          src={CartImage}
          alt="CartImage"
          className="h-6 transform transition-transform hover:scale-110 cursor-pointer mt-2"
        />
        {totalCartCount > 0 && (
          <span
            className={`absolute bg-btn-from text-white text-xs flex items-center justify-center top-[4px] right-0 transform translate-x-1/2 -translate-y-1/2 
                    ${
                      totalCartCount > 99
                        ? "w-7 h-5 rounded-[90px]"
                        : "w-5 h-5 rounded-full"
                    }
                `}
          >
            {cart && (totalCartCount > 99 ? "99+" : totalCartCount)}
          </span>
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-50 w-96 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          <div className="rounded-large shadow-lg ring-1 ring-black ring-opacity-5 bg-background">
            <div className="pt-5 bg-white">
              {cart?.basketItems?.length > 0 ? (
                <div className="max-h-48 overflow-y-auto hide-scrollbar">
                  {cart.basketItems.map((item: CartItem) => {
                    return (
                      <ProductCard
                        key={item.id}
                        cartItem={item}
                        onDeleteItem={handleDeleteItem}
                        isCheckoutPage={false}
                      />
                    );
                  })}
                </div>
              ) : (
                <EmptyCart className="p-10" />
              )}
            </div>
            <div className="px-5 pb-5 grid grid-cols-6 gap-4 bg-white">
              <div className="col-start-1 col-end-4">
                <Button
                  text="Checkout"
                  inverse
                  onClick={navigateToCheckout}
                  className="w-full shadow-none"
                  disabled={cart?.basketItems?.length < 1 || invalidCart}
                />
              </div>
              <div className="col-start-4 col-end-8">
                <Button
                  text="Go shopping"
                  onClick={navigateToHome}
                  className="w-full shadow-none"
                />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
