import apiService from "api/apiServices";
import { Banner } from "models/banner";
import { API_DEV_URL } from "utils/constants";

export type BannerListResponse = {
  totalCount: number;
  items: Banner[];
};

export const getBanners = async (): Promise<BannerListResponse> => {
  const response = await apiService.get<BannerListResponse>(
    `${API_DEV_URL}/app/banner`
  );

  return response.data;
};
