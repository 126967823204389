import { login } from "api/login";
import { PATH_NAMES, TESTING_AUTH_DATA } from "./constants";

declare global {
  interface Window {
    callBinanceMiniProgram: (data: string) => void;
    bn: any;
  }
}

export const handleLogin = () => {
  const isProduction = process.env.REACT_APP_IS_BINANCE_LOGIN;

  if (
    window.location.hostname === PATH_NAMES.DEVELOP ||
    window.location.hostname === PATH_NAMES.LOCALHOST ||
    window.location.hostname === PATH_NAMES.DEVELOP_NEW
    // || window.location.hostname === PATH_NAMES.PRODUCTION
    // || window.location.hostname === PATH_NAMES.PRODUCTION2
  ) {
    login(TESTING_AUTH_DATA)
      .then((res) => {
        if (res) {
          console.log("##debug res", res);
          localStorage.setItem("isLoggedIn", "true");
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.error("Binance auth failed", err);
      });
  } else 
  if (window.bn && window.bn.miniProgram && isProduction === "true") {
    console.log("##debug isProduction", isProduction);
    window.callBinanceMiniProgram(JSON.stringify({ action: "login" }));
  } else {
    console.log("##debug is not production", isProduction);
    const clientId = process.env.REACT_APP_BINANCE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_BINANCE_REDIRECT_URI;
    const binanceOAuthUrl = process.env.REACT_APP_BINANCE_OAUTH_URL;
    const binanceOAuthScope = process.env.REACT_APP_BINANCE_OAUTH_SCOPE;
    if (!clientId || !redirectUri || !binanceOAuthUrl || !binanceOAuthScope) {
      console.error("Some of the oauth environment variables are missing");
      return;
    }
    const state = Math.random().toString(36).substring(2);
    sessionStorage.setItem("state", state);
    const authorizationUrl = `${binanceOAuthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${binanceOAuthScope}`;
    window.location.href = authorizationUrl;
  }
};

