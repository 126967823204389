import { AddBasketItem } from "models/basketItem";
import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";

export const addToBasket = async (basket: AddBasketItem): Promise<any> => {
  return apiService
    .post(`${API_DEV_URL}/app/basket/item`, basket)
    .then((res: any) => {
      return res;
    });
};