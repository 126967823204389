import { Cart, CartItem } from "models/cartItem";
import { removeFromBasket } from "api/removeFromBasket";

export const removeItemFromCart = async (item: CartItem) => {
  try {
    await removeFromBasket(item.id);
  } catch (error) {
    console.error("Error removing item from cart:", error);
  }
};

export const deleteItem = (
  item: CartItem,
  setCart: React.Dispatch<React.SetStateAction<Cart>>
) => {
  console.log("##delete delete item in deleteItem", item);
  setCart((prevCart: Cart) => {
    const updatedBasketItems = prevCart.basketItems.filter(
      (cartItem: CartItem) =>
        !(
          cartItem.id === item.id &&
          cartItem.unitFaceValue === item.unitFaceValue
        )
    );

    const updatedTotalQuantity = updatedBasketItems.reduce(
      (acc, cartItem) => acc + cartItem.quantity,
      0
    );

    const updatedCart = {
      ...prevCart,
      basketItems: updatedBasketItems,
      totalItemQuantity: updatedTotalQuantity,
    };

    console.log("##delete Updated cart prevCart", prevCart);
    console.log("##delete Updated cart updatedCart", updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    console.log("##delete Updated cart set to localStorage:", updatedCart);
    const cartValue = localStorage.getItem("cart");
    console.log("##delete localstoreage value", cartValue);

    return updatedCart;
  });
  removeItemFromCart(item);
};
