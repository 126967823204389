import apiService from "api/apiServices";
import { BrandCard } from "models/brandCard";
import { API_DEV_URL } from "utils/constants";

export const addToWishList = async (brandId?: string): Promise<BrandCard> => {
  const response = await apiService.post<BrandCard>(
    `${API_DEV_URL}/app/user-wishlist-brand`,
    {
      brandId,
    }
  );

  return response.data;
};
