import { OrderStatusIcon } from "components/common/orderStatusIcon";
import { ORDER_STATUS_OPTIONS, OrderCode } from "models/order";
import CheckCircleIcon from "assets/check_circle.svg";
import { InfoList } from "components/common/infoList";
import { Badge } from "components/common/badge";
import { getStatusType } from "utils/getStatusType";
import ReactCountryFlag from "react-country-flag";
import { dateFormatterWithTime } from "utils/dateUtils";
import { ShowCode } from "components/layout/showCode";
import { useState } from "react";
import { Modal } from "components/common/modal";
import { CopyCode } from "components/layout/copyCode";
import Button from "components/common/button/button";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "routes/routes";
import { CardDetail } from "./cardDetail";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import CopyButton from "components/common/CopyButton";

interface Props {
  status: string;
}

export const OrderStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenCode, setIsOpenCode] = useState(false);
  const [selectedCode, setSelectedCode] = useState<any>();

  const onOpenCode = (code: OrderCode, product: string) => {
    setSelectedCode(code);
    setIsOpenCode(true);
  };

  const onCopyCode = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const onProceed = () => {
    onCopyCode(selectedCode?.code?.code);
    setIsOpenCode(false);
  };

  const navigateToPage = (route: string) => navigate(route);
  const order = location.state?.order;
  // const order = {
  //   supplierOrderId: null,
  //   marketplaceOrderId: "a3f6bcef-db98-4b6b-8864-81f645203673",
  //   orderStatus: "Created",
  //   deliveryMethod: "Email",
  //   totalItemQuantity: 1,
  //   totalItemPrice: 1.038,
  //   clientEmail: "vishu@gmail.com",
  //   errorMessage: null,
  //   orderItems: [],
  //   lastModificationTime: null,
  //   creationTime: "2024-08-14T09:05:02.943817",
  //   id: "3a14624f-05dc-fcb4-6beb-95deb4e4995b",
  // };

  if (!order) {
    navigate(routes.checkout);
    return null;
  }

  const status = order.orderStatus;

  return (
    <div className="overflow-y-auto pb-4">
      <OrderStatusIcon status={status} />
      <div className="text-left">
        <div className="flex mt-4">
          <img src={CheckCircleIcon} alt="check" className="h-6" />
          <div className="text-xs font-normal text-primary ml-2 w-60">
            We’ve delivered your Order to{" "}
            <span className="text-primary-purple cursor-pointer">
              {order.clientEmail}
            </span>
          </div>
        </div>

        <div className="flex mt-3">
          <img src={CheckCircleIcon} alt="check" className="h-6" />
          <div className="text-xs font-normal text-primary ml-2 w-60">
            In case of any mistake copy the Order# and{" "}
            <span className="text-primary-purple cursor-pointer">
              Contact us
            </span>
          </div>
        </div>
        <div className="mt-5">
          {/* <div className="text-base font-semibold text-primary">
            PlayStation Poland
          </div> */}
          <div className="mt-2 p-2 shadow-card rounded">
            <InfoList
              label="Status"
              value={
                <Badge
                  text={order.orderStatus}
                  type={getStatusType(order.orderStatus || "")}
                />
              }
            />
            {/* <InfoList
              label="Location"
              value={
                <div className="flex flex-row gap-1 align-middle mr-1">
                  <ReactCountryFlag
                    countryCode={"PL" || ""}
                    svg
                    className="rounded-sm"
                  />
                  <div className="-mt-0.5">Poland</div>
                </div>
              }
            /> */}
            <InfoList label="Method" value={order.deliveryMethod} />
            <InfoList
              label="Order ID"
              value={
                <div className="flex rounded-large bg-selected px-2 py-1 w-60">
                  <div className="col-span-11 text-sm font-normal text-primary truncate text-ellipsis">
                    {order.id}
                  </div>
                  <CopyButton textToCopy={order.id} />
                </div>
              }
            />
            {/* <InfoList
              label="Date and time"
              value={dateFormatterWithTime("2023-11-01" || "")}
            /> */}
            {/* <InfoList label="Seller" value="Playstation" /> */}
          </div>
        </div>

        {/* <div className="mt-2">
          <div className="flex flex-row w-full justify-between p-1 ">
            <span className="text-sm font-normal text-primary">
              Playstation Poland 15 PLN
            </span>
            <div className="text-sm font-normal text-primary text-right px-4">
              | X3
            </div>
          </div>
          <div className="flex flex-row w-full justify-between p-1 ">
            <span className="text-sm font-normal text-primary">
              Playstation Poland 15 PLN
            </span>
            <div className="text-sm font-normal text-primary text-right px-4">
              | X3
            </div>
          </div>
        </div> */}
      </div>
      {/* isRedeemed: boolean; cardStatus: string;
      <div className="mt-5 -ml-3">
        <ShowCode
          codes={[
            {
              cardStatus: "cardStatus 1",
              isRedeemed: true,
              id: "1",
            },
            {
              cardStatus: "code 2",
              isRedeemed: false,
              id: "2",
            },
          ]}
          title="Playstation Polan 15 PLN"
          onSelectCode={() =>
            onOpenCode(
              {
                cardStatus: "code 2",
                isRedeemed: false,
                id: "2",
              },
              "Playstation Polan 15 PLN"
            )
          }
        />
      </div> */}
      {/* <div className="static  flex float-right">
        <ArrowDownCircleIcon className=" h-16 w-16 text-primary-blue cursor-pointer" />
      </div> */}
      {/* <CardDetail 
        status="Succeeded"
        title="Playstaion Poland 15 PLN"
        info={[
          { name: "Playstation Poland 15 PLN", value: "| X3" },
          { name: "Playstation Poland 25 PLN", value: "| X4" },
        ]}
      /> */}
      {/* <div className="mt-2">
        <CardDetail
          status="Failed"
          info={[
            { name: "Playstation Poland 15 PLN", value: "| X3" },
            { name: "Playstation Poland 25 PLN", value: "| X4" },
          ]}
        />
      </div> */}
      <div className="mt-5 text-left text-xs font-normal text-primary">
        You can find your codes in orders section anytime
      </div>
      <div className="mt-5 flex gap-3">
        <Button
          text="See my orders"
          onClick={() => navigateToPage(routes.orders)}
          className="w-full shadow-none"
          inverse
        />
        <Button
          text={
            status === ORDER_STATUS_OPTIONS.Delivered ||
            status === ORDER_STATUS_OPTIONS.Created
              ? "Go shopping"
              : "Try again"
          }
          onClick={() => navigateToPage(routes.index)}
          className="w-full shadow-none"
        />
      </div>
      {selectedCode && (
        <Modal
          isOpen={isOpenCode}
          setIsOpen={setIsOpenCode}
          title={selectedCode?.title}
          content={
            <CopyCode code={selectedCode.code} onCopyCode={onCopyCode} />
          }
          onProceed={onProceed}
          cancelText="Close"
          proceedText="Copy"
        />
      )}
    </div>
  );
};
