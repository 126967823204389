import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";

export const removeFromBasket = async (itemId: string): Promise<any> => {
  console.log('##delete itemId in removeFromBasket', itemId);
  return apiService
    .delete(`${API_DEV_URL}/app/basket/item/${itemId}`)
    .then((res: any) => {
      return res;
    });
};
