import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { TextField } from "components/common/textField";
import ReactCountryFlag from "react-country-flag";
import { Country } from "models/country";
import GlobalFlag from "assets/globe.svg";

interface InputProps {
  data: Country[];
  selectedOption: Country | null;
  onSelect: (country: Country) => void;
  placeholder?: string;
  inputPlaceholder?: string;
}

export const CountryDropdown = ({
  data,
  selectedOption,
  onSelect,
  placeholder,
  inputPlaceholder,
}: InputProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const options =
    searchTerm === ""
      ? data
      : data.filter(
          (x) => x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        );

  return (
    <Popover>
      <>
        <Popover.Button
          className="w-full h-8 text-white focus:outline-none 
                focus-visible:ring-2 focus-visible:ring-white"
        >
          <button
            className={`w-full h-8 p-0 rounded-large cursor-pointer text-sm font-normal text-input-label shadow-none min-w-[60px] hover:shadow-none ${
              selectedOption
                ? "border border-input-border-selected"
                : "border border-input-border"
            }`}
          >
            <div className="grid grid-cols-2 px-2">
              <div className="flex justify-center items-center">
                {selectedOption ? (
                  selectedOption.name === "All" ? (
                    <img
                      src={GlobalFlag}
                      alt="Global Icon"
                      className="w-[1.25em] h-[1.25em]"
                    />
                  ) : (
                    <ReactCountryFlag countryCode={selectedOption?.code} svg />
                  )
                ) : null}
              </div>
              <ChevronDownIcon className="h-6 text-input-icon ml-auto" />
            </div>
          </button>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute left-[43%] z-30 w-96 max-w-xs -translate-x-1/2 transform px-4 sm:px-0 mt-2">
            {({ close }) => (
              <div className="overflow-hidden rounded-large shadow-lg min-w-72">
                <div className="relative bg-white p-2">
                  <TextField
                    searchIcon={true}
                    placeholder={inputPlaceholder || "Search"}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    className="border-2 border-selected shadow-none hover:shadow-none"
                  />
                  <div className="max-h-80 overflow-x-hidden overflow-y-scroll">
                    {options && options.length > 0 ? (
                      options.map((option, index) => (
                        <div
                          key={index}
                          className={`relative flex items-center hover:bg-selected cursor-pointer rounded-large py-1 my-1 pr-6 whitespace-nowrap text-left ${
                            selectedOption?.value === option.value &&
                            "bg-selected"
                          }`}
                          onClick={() => {
                            onSelect(option);
                            close && close();
                            setSearchTerm("");
                          }}
                        >
                          {option.name === "All" ? (
                            <img
                              src={GlobalFlag}
                              alt="Global Icon"
                              className="w-[1em] h-[1em] rounded-md mx-2 -mt-1 text-xl"
                            />
                          ) : (
                            <ReactCountryFlag
                              countryCode={option.code}
                              svg
                              className="rounded-md mx-2 -mt-1 text-xl"
                            />
                          )}

                          {option.name}
                          {selectedOption?.value === option.value && (
                            <span className="absolute right-6 flex items-center pl-3 text-btn-from">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      ))
                    ) : (
                      <span className="text-input-label">Nothing found.</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};
