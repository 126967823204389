import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";

interface CountryResponse {
  name: string;
  id: string;
  code: string;
  alpha3Code: string;
}

export const getCountries = async (): Promise<CountryResponse[]> => {
    const response = await apiService.get<CountryResponse[]>(
      `${API_DEV_URL}/app/country`
    );

    return response.data;
};
