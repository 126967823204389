import React, { useState, createContext, useContext, ReactNode } from "react";

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedInState] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const cookieLoggedIn =
      getCookie(".AspNetCore.Identity.Application") !== null;
    console.log('##debug storedIsLoggedIn', storedIsLoggedIn);
    console.log('##debug cookieLoggedIn', cookieLoggedIn);
    console.log('##debug stored cookie', getCookie(".AspNetCore.Identity.Application"))
    return storedIsLoggedIn;
  });

  const setIsLoggedIn = (value: boolean) => {
    setIsLoggedInState(value);
    localStorage.setItem("isLoggedIn", value.toString());
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
};
