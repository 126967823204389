import ProfileImageDefault from "assets/profile_default.svg";

interface Props {
  name: string;
  imageUrl?: string;
}

export const ImageWithName = ({ imageUrl, name }: Props) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="flex h-24 w-24 flex-col items-center justify-center rounded-full bg-selected">
          <img
            alt="profile_pic"
            src={imageUrl && imageUrl !== "" ? imageUrl : ProfileImageDefault}
            className="h-16 w-16 p-1"
          />
        </div>
      </div>
      <div className="text-lg font-medium text-primary break-words">{name}</div>
    </>
  );
};
