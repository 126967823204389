import { User } from "models/User";
import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";

export const updateProfile = async (user: User): Promise<User> => {
  const response = await apiService.put<User>(
    `${API_DEV_URL}/account/my-profile`, user
  );

  return response.data;
};
