import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import { API_DEV_URL } from "utils/constants";

// Create a new Axios instance with custom configuration
const apiService: AxiosInstance = axios.create({
  baseURL: API_DEV_URL,
  timeout: 10000, // Request timeout in milliseconds,
  withCredentials: true,
});

// Request Interceptor
apiService.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Add the authentication token to the request headers
    // config.headers.Authorization = `Basic ${API_KEY}`; //`Basic ${process.env.REACT_APP_AUTH_TOKEN}`;
    config.headers.Accept = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";

    config.headers.common = {
      "Access-Control-Allow-Origin": "*",
    };

    // You can also add other headers or request-specific settings here
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response Interceptor
apiService.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify the response data before it's returned
    // You can perform data transformation or validation here
    return response;
  },
  (error: AxiosError) => {
    // Handle response errors
    if (error.response) {
      // The request was made, but the server responded with an error status code
      const { data, status } = error.response;

      // You can log or handle specific error cases here
      console.error(`Request failed with status ${status}:`, data);
    } else if (error.request) {
      // The request was made, but no response was received
      console.error("No response received from the server");
    } else {
      // Something happened in setting up the request
      console.error("Error setting up the request:", error.message);
    }

    return Promise.reject(error);
  }
);

export default apiService;
