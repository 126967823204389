import React, { useEffect, useState, useCallback, useContext } from "react";
import Spinner from "components/common/loader/spinner/Spinner";
import { getPaymentDetails } from "api/getPayment";
import { useToast } from "components/common/toast/toastService";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { DEFAULT_ORDER_ID, PAYMENT_STATUSES } from "utils/constants";
import { getOrderById } from "api/getOrderById";
import { AppContext } from "context/appContext";

const VerifyPayment: React.FC = () => {
  const [retryCount, setRetryCount] = useState<number>(0);
  const retryMaxCount: number = 10;
  const toast = useToast();
  const navigate = useNavigate();
  const paymentId: string | null = sessionStorage.getItem("paymentId");
  const { setCart } = useContext(AppContext);

  const fibonacci = useCallback((n: number): number => {
    if (n <= 1) return n;
    let a = 0;
    let b = 1;
    let result = 0;
    for (let i = 2; i <= n; i++) {
      result = a + b;
      a = b;
      b = result;
      if (result > 55) return 55;
    }
    return result;
  }, []);

  const callApiWithRetry = useCallback(
    async (id: string): Promise<void> => {
      if (retryCount >= 55) {
        navigate(routes.orders, {
          state: { showToast: true },
        });
        return;
      }
      try {
        const response = await getPaymentDetails(id);
        console.log("##debug response", response);

        if (response && response.data) {
          const { paymentStatus, orderId } = response.data;
          console.log("##debug paymentStatus", paymentStatus);
          if (
            paymentStatus === PAYMENT_STATUSES.PAID &&
            orderId !== DEFAULT_ORDER_ID
          ) {
            // Call the order details API
            const orderResponse = await getOrderById(orderId);
            console.log("##debug orderResponse", orderResponse);
            if (orderResponse && orderResponse.data) {
              toast.open("Success", "Order placed successfully!", "success", 5000);
              setCart({ basketItems: [] });
              localStorage.removeItem("cart");
              navigate(routes.orderStatus, {
                state: { order: orderResponse.data },
              });
            } else {
              throw new Error("Order details API call failed");
            }
          } else {
            throw new Error("Payment not completed or order ID not updated");
          }
        } else {
          throw new Error("API call failed");
        }
      } catch (error) {
        console.error("API call failed, retrying...", error);

        if (retryCount > 0) {
          const retryInterval: number = fibonacci(retryCount) * 1000;
          console.log("##debug retryCount", retryCount);
          console.log("##debug retryInterval", retryInterval);
          if (retryCount < retryMaxCount) {
            console.log("##debug Retrying in", retryInterval, "ms");
            setTimeout(() => {
              setRetryCount((prevCount) => prevCount + 1);
            }, retryInterval);
          } else {
            console.error(
              "Payment verification failed. Please contact support.",
              error
            );
          }
          console.log("##debug #########################################");
        } else {
          setRetryCount((prevCount) => prevCount + 1);
        }
      }
    },
    [retryCount, retryMaxCount, fibonacci, toast, navigate]
  );

  useEffect(() => {
    if (paymentId) {
      console.log("##debug paymentId", paymentId);
      callApiWithRetry(paymentId);
    } else {
      console.log("##debug Payment ID not found");
      toast.open(
        "Error",
        "Payment ID not found. Please try again.",
        "error",
        5000
      );
      navigate(routes.checkout);
    }
  }, [callApiWithRetry, paymentId, toast, navigate]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default VerifyPayment;
