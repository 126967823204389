import apiService from "api/apiServices";
import { API_DEV_URL } from "utils/constants";
import { BrandsListResponse } from "./getBrandList";

export type WishlistArguments = {
  isDescending?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};

export const getWishList = async (
  args?: WishlistArguments
): Promise<BrandsListResponse> => {
  const response = await apiService.get<BrandsListResponse>(
    `${API_DEV_URL}/app/user-wishlist-brand`,
    {
      params: args,
    }
  );

  return response.data;
};
