import React, { useCallback, useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortButton } from "components/common/button/sortButton";
import Button from "components/common/button/button";
import { ProductCard } from "components/common/productCard";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import { Option } from "models/options";
import {
  BrandArguments,
  BrandsListResponse,
  getBrandList,
} from "api/getBrandList";
import { BrandCard } from "models/brandCard";
import { getSortOptions } from "utils/getSortOptions";

interface Props {
  products: BrandsListResponse;
  onAddToFavorite: (productId: string) => void;
  isHorizontal: boolean;
  title: string;
  setSortSelected?: (value: Option) => void;
  sortSelected?: Option;
  setSelectedSection: (value: string) => void;
  productsLoading: boolean;
  specialProductsLoading: boolean;
  popularProductsLoading: boolean;
  filters: BrandArguments;
  selectedSection: string;
  section: string;
  isScrollLocked?: boolean;
  onDisplayedProductsChange: (value: BrandCard[]) => void;
  // selectedCategory: string;
}

const ShimmerEffectLoader = (
  <div className="">
    <ShimmerEffect
      width="100%"
      height="120px"
      borderRadius="20px"
      miniLoaderSizeOne="picture"
      miniLoaderSizeTwo="medium"
      miniLoaderSizeThree="small"
    />
  </div>
);

const InfiniteScrollLoader = () => (
  <div className="grid grid-cols-2 gap-4 mt-4">
    {Array.from({ length: 2 }).map((_, idx) => ShimmerEffectLoader)}
  </div>
);

export const BrandList = ({
  products,
  onAddToFavorite,
  isHorizontal,
  title,
  setSortSelected,
  sortSelected,
  selectedSection,
  setSelectedSection,
  productsLoading,
  specialProductsLoading,
  popularProductsLoading,
  filters,
  section,
  isScrollLocked,
  onDisplayedProductsChange,
  // selectedCategory,
}: Props) => {
  const [displayedProducts, setDisplayedProducts] = useState<BrandCard[]>([]);
  console.log("##debug displayedProducts length", displayedProducts.length);
  const [hasMore, setHasMore] = useState(true);
  // const [skipCount, setSkipCount] = useState(16);
  const maxResultCount = 16;
  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState<
    string | number
  >("auto");
  const brandSortOptions = getSortOptions("brands");
  const scrollPositionRef = useRef<number>(0);
  const skipCountRef = useRef(0);
  console.log("##skipCountRef", skipCountRef);
  const [previousCategory, setPreviousCategory] = useState<string | null>(null);
  console.log('##debug1 previousCategory', previousCategory);

  useEffect(() => {
    console.log(
      "##debug Updated displayedProducts length:",
      displayedProducts.length
    );
  }, [displayedProducts]);

  useEffect(() => {
    console.log(`##z Selected Section: ${selectedSection}`);
    console.log(`##z Displayed Products: `, displayedProducts);
  }, [selectedSection, displayedProducts, products]);
  

  const fetchMoreData = useCallback(async () => {
    try {
      let type = "";
      if (selectedSection === "Special Offers") {
        type = "special-offers";
      } else if (selectedSection === "Popular") {
        type = "popular";
      }
      const currentSkipCount = skipCountRef.current;
      console.log("Fetching with skipCount:", currentSkipCount);
      const fetchSkipCount = currentSkipCount === 0 ? 16 : currentSkipCount;
      const newProductsData = await getBrandList(
        {
          ...filters,
          skipCount: fetchSkipCount,
        },
        type
      );

      const newProducts = newProductsData.items;
      if (newProducts.length > 0) {
        setDisplayedProducts((prevProducts) => [
          ...prevProducts,
          ...newProducts,
        ]);
        skipCountRef.current = fetchSkipCount + maxResultCount;
      }

      if (newProducts.length < maxResultCount) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch more data:", error);
      setHasMore(false);
    }
  }, [filters, selectedSection]);

  useEffect(() => {
    skipCountRef.current = 0;
  }, [sortSelected]);

  // useEffect(() => {
  //   if (selectedCategory !== previousCategory) {
  //     console.log('##debug1 if (selectedCategory !== previousCategory)', selectedCategory);
  //     setPreviousCategory(selectedCategory);
  //   }
  // }, [selectedCategory, previousCategory]);
  

  useEffect(() => {
    // skipCountRef.current = 0;
    console.log(
      "##debug Products prop changed, setting displayedProducts:",
      products.items
    );
    // if (
    //   products.items.length > displayedProducts.length ||
    //   previousCategory !== selectedCategory
    // ) {
      console.log("##debug setting displayedProducts");
      setDisplayedProducts(products.items);
      onDisplayedProductsChange(products.items);
    // }
    if (products.items.length < maxResultCount) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [products, previousCategory]);

  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
  }, []);

  useEffect(() => {
    const isSeeAllSection =
      selectedSection === "Popular" || selectedSection === "Special Offers";

    if (isSeeAllSection) {
      setInfiniteScrollHeight(700);
    } else if (isScrollLocked) {
      setInfiniteScrollHeight(700);
    } else {
      setInfiniteScrollHeight("auto");
    }
  }, [isScrollLocked, selectedSection]);

  const onSelectSeeAll = () => {
    setSelectedSection(title);
  };

  const isOffersOrPopularSection =
    title === "Popular" || title === "Special Offers";
  const shimmerItemCount = 8;

  const renderProductGrid = () => (
    <div
      className={`${
        isHorizontal
          ? "mt-2 flex overflow-x-auto gap-2 hide-scrollbar"
          : "grid grid-cols-2 gap-4 hide-scrollbar"
      }`}
    >
      {products.items.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          onAddToFavorite={onAddToFavorite}
          isSingleProduct={products.items.length === 1}
        />
      ))}
    </div>
  );

  const isCurrentSectionLoading = () => {
    if (selectedSection === "All") {
      return productsLoading;
    } else if (selectedSection === "Special Offers") {
      return specialProductsLoading;
    } else if (selectedSection === "Popular") {
      return popularProductsLoading;
    }
    return false;
  };

  return (
    <>
      <div className={"flex flex-row w-full justify-between mt-2"}>
        <div className="text-lg font-medium text-primary">{title}</div>
        {setSortSelected ? (
          <div className="-mr-2 relative">
            <SortButton
              setSelected={setSortSelected}
              selected={sortSelected}
              options={brandSortOptions}
            />
          </div>
        ) : (
          <Button
            text="See All"
            onClick={onSelectSeeAll}
            className="w-20 h-6 p-0 text-md font-medium"
          />
        )}
      </div>

      <div>
        {isCurrentSectionLoading() ? (
          <div className="grid grid-cols-2 gap-4 w-full">
            {Array.from({ length: shimmerItemCount }).map(
              (_, idx) => ShimmerEffectLoader
            )}
          </div>
        ) : isOffersOrPopularSection &&
          !(
            selectedSection === "Popular" ||
            selectedSection === "Special Offers"
          ) ? (
          renderProductGrid()
        ) : section === "" ? (
          <InfiniteScroll
            key="infinite-scroll"
            dataLength={displayedProducts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<InfiniteScrollLoader />}
            scrollThreshold={0.9}
            height={infiniteScrollHeight}
          >
            <div
              className={`${
                isHorizontal
                  ? "mt-2 flex overflow-x-auto gap-2"
                  : "grid grid-cols-2 gap-4 hide-scrollbar"
              }`}
            >
              {displayedProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onAddToFavorite={onAddToFavorite}
                  isSingleProduct={products.items.length === 1}
                />
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          renderProductGrid()
        )}
      </div>
    </>
  );
};
