import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Header } from "components/common/header";
import { CategoriesTab } from "components/pages/home/components/categoriesTab";
import { getCurrencies } from "api/getCurrencies";
import {
  getBrandList,
  BrandArguments,
  BrandsListResponse,
} from "api/getBrandList";
import { getCountries } from "api/getCountries";
import { Banners } from "./components/banners";
import { MainSearchField } from "./components/mainSearch";
import { BrandList } from "./components/brandList";
import { useToast } from "components/common/toast/toastService";
import { Option } from "models/options";
import { Country } from "models/country";
import { BrandCard } from "models/brandCard";
import { addToWishList } from "api/addToWishlist";
import { removeFromWishlist } from "api/removeFromWishlist";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import { useLocation } from "react-router-dom";

export const Home = () => {
  const [sortSelected, setSortSelected] = useState<Option | undefined>(
    undefined
  );
  const [specialSortSelected, setSpecialSortSelected] = useState<
    Option | undefined
  >(undefined);
  const [popularSortSelected, setPopularSortSelected] = useState<
    Option | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const toast = useToast();
  const toastRef = useRef(toast);
  const [currency, setCurrency] = useState<Option>(() => {
    const savedCurrency = localStorage.getItem("selectedCurrency");
    return savedCurrency ? JSON.parse(savedCurrency) : { name: "$", value: "" };
  });
  const [brand, setBrand] = useState<any>(null);
  const [searchedBrands, setSearchedBrands] = useState<Option[]>([]);
  const [country, setCountry] = useState<Country>(() => {
    const savedCountry = localStorage.getItem("selectedCountry");
    return savedCountry
      ? JSON.parse(savedCountry)
      : { name: "All", value: "", code: "GL" };
  });
  const [filteringData, setFilteringData] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>("All");
  const [previousSection, setPreviousSection] = useState<string | null>(null);
  const [brandSelected, setBrandSelected] = useState<boolean>(false);
  const allSectionRef = useRef<HTMLDivElement | null>(null);
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const location = useLocation();
  const scrollPositionRef = useRef(0);
  const [displayedProducts, setDisplayedProducts] = useState<BrandCard[]>([]);
  const onDisplayedProductsChange = (updatedProducts: BrandCard[]) => {
    setDisplayedProducts(updatedProducts);
  };

  console.log("##displayedProducts1", displayedProducts);

  interface ProductQueryData {
    items: BrandCard[];
    totalCount: number;
  }

  const transformOptionToBrandCard = (option: Option): BrandCard => ({
    id: option.value,
    name: option.name,
    logoUrl: option.logoUrl ?? "",
    countryCode: option.countryCode ?? "",
    currencyCode: option.currencyCode ?? "",
    creationTime: "",
    isInWishlist: false,
    discount: option.discount,
  });

  const defaultPageSize = 16;

  const [pagination, setPagination] = useState({
    pageSize: defaultPageSize,
    pageIndex: 0,
  });

  const getFilters = useCallback(
    (section: string) => {
      const sortOption =
        section === "All"
          ? sortSelected
          : section === "Special Offers"
          ? specialSortSelected
          : popularSortSelected;
      return {
        countryId: country?.value,
        ...(brand?.name ? { brandName: brand?.name } : null),
        ...(selectedCategory ? { categoryIds: selectedCategory } : null),
        ...(sortOption?.field
          ? { sorting: sortOption.field, isDescending: sortOption.isDescending }
          : {}),
        currencyId: currency?.value,
        maxResultCount: defaultPageSize,
        skipCount: pagination.pageSize * pagination.pageIndex,
      };
    },
    [
      brand,
      country,
      currency,
      selectedCategory,
      sortSelected,
      specialSortSelected,
      popularSortSelected,
      pagination,
    ]
  );

  const resetPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
    scrollPositionRef.current = 0;
  };

  useEffect(() => {
    if (selectedCategory) {
      localStorage.setItem("selectedCategory", selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const savedCategory = localStorage.getItem("selectedCategory");
    if (savedCategory) {
      setSelectedCategory(savedCategory);
    }
  }, []);

  useEffect(() => {
    const clearSelectedCategoryOnRefresh = () => {
      localStorage.removeItem("selectedCategory");
    };

    window.addEventListener("beforeunload", clearSelectedCategoryOnRefresh);

    return () => {
      window.removeEventListener(
        "beforeunload",
        clearSelectedCategoryOnRefresh
      );
    };
  }, []);

  const allSectionFilters = useMemo(() => getFilters("All"), [getFilters]);
  const specialOffersFilters = useMemo(
    () => getFilters("Special Offers"),
    [getFilters]
  );
  const popularFilters = useMemo(() => getFilters("Popular"), [getFilters]);

  const queryClient = useQueryClient();

  const fetchCurrencies = async () => {
    const result = await getCurrencies();
    return result.map((currency: any) => ({
      name: currency.code,
      value: currency.id,
    }));
  };

  const fetchCountries = async () => {
    const result = await getCountries();
    return result.map((country: any) => ({
      name: country.name,
      value: country.id,
      code: country.code,
    }));
  };

  const fetchProducts = async (filters: BrandArguments) => {
    const result = await getBrandList(filters);
    return result;
  };

  const fetchSpecialProducts = async (filters: BrandArguments) => {
    const specialResult = await getBrandList(filters, "special-offers");
    return specialResult;
  };

  const fetchPopularProducts = async (filters: BrandArguments) => {
    const popularResult = await getBrandList(filters, "popular");
    return popularResult;
  };

  useEffect(() => {
    if (currency) {
      localStorage.setItem("selectedCurrency", JSON.stringify(currency));
    }
  }, [currency]);

  useEffect(() => {
    if (country) {
      localStorage.setItem("selectedCountry", JSON.stringify(country));
    }
  }, [country]);

  const clearFilters = () => {
    setCountry({ name: "All", value: "", code: "GL" });
    setCurrency({ name: "$", value: "" });
    localStorage.removeItem("selectedCountry");
    localStorage.removeItem("selectedCurrency");
  };

  const {
    data: currencies,
    error: currenciesError,
    isLoading: currenciesLoading,
  } = useQuery({
    queryKey: ["currencies"],
    queryFn: fetchCurrencies,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const {
    data: countries,
    error: countriesError,
    isLoading: countriesLoading,
  } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const {
    data: products,
    isLoading: productsLoading,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ["products", allSectionFilters],
    queryFn: () => fetchProducts(allSectionFilters),
    enabled: !brandSelected && Boolean(allSectionFilters),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  const {
    data: specialProducts,
    isLoading: specialProductsLoading,
    refetch: refetchSpecialProducts,
  } = useQuery({
    queryKey: ["specialProducts", specialOffersFilters],
    queryFn: () => fetchSpecialProducts(specialOffersFilters),
    enabled: !brandSelected && Boolean(specialOffersFilters),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  const {
    data: popularProducts,
    isLoading: popularProductsLoading,
    refetch: refetchPopularProducts,
  } = useQuery({
    queryKey: ["popularProducts", popularFilters],
    queryFn: () => fetchPopularProducts(popularFilters),
    enabled: !brandSelected && Boolean(popularFilters),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  useEffect(() => {
    if (location.pathname === "/") {
      refetchProducts();
      refetchPopularProducts();
      refetchSpecialProducts();
    }
  }, [
    location,
    refetchProducts,
    refetchPopularProducts,
    refetchSpecialProducts,
  ]);

  const noProductsFound =
    products?.totalCount === 0 &&
    specialProducts?.totalCount === 0 &&
    popularProducts?.totalCount === 0;

  useEffect(() => {
    if (currenciesError) {
      toastRef.current.open(
        "Error",
        "Failed to fetch Currencies",
        "error",
        5000
      );
    }

    if (countriesError) {
      toastRef.current.open(
        "Error",
        "Failed to fetch Currencies",
        "error",
        5000
      );
    }
  }, [currenciesError, countriesError]);

  const transformBrandCardToOption = (brandCards: BrandCard[]): Option[] => {
    return brandCards.map((brand) => ({
      value: brand.id,
      name: brand.name,
      logoUrl: brand.logoUrl,
      countryCode: brand.countryCode,
      currencyCode: brand.currencyCode,
    }));
  };

  useEffect(() => {
    if (products && products.items.length > 0 && searchedBrands.length > 0) {
      setSearchedBrands(transformBrandCardToOption(products.items));
    }
  }, [products]);

  useEffect(() => {
    const handleScroll = () => {
      const allSection = allSectionRef.current;
      if (!allSection) return;
      const rect = allSection.getBoundingClientRect();

      if (rect.top <= 170 && !isScrollLocked) {
        setIsScrollLocked(true);
        allSection.style.overflowY = "scroll";
        allSection.style.height = "calc(100vh - 160px)";
      } else if (rect.top > 170 && isScrollLocked) {
        setIsScrollLocked(false);
        allSection.style.overflowY = "auto";
        allSection.style.height = "auto";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "auto";
    };
  }, [isScrollLocked]);

  const transformedSearchedBrands = searchedBrands.map(
    transformOptionToBrandCard
  );

  useEffect(() => {
    if (
      // country?.value || // commented for issue TAMANA-133
      // currency?.value || // commented for issue TAMANA-202
      brand ||
      // selectedCategory || commented for issue TAMANA-148
      selectedSection !== "All"
    ) {
      setFilteringData(true);
    } else {
      setFilteringData(false);
    }

    if (
      !brandSelected &&
      selectedSection !== previousSection &&
      transformedSearchedBrands.length === 0
    ) {
      if (selectedSection === "Special Offers") {
        refetchSpecialProducts();
      } else if (selectedSection === "Popular") {
        refetchPopularProducts();
      } else {
        refetchProducts();
      }
      setPreviousSection(selectedSection);
    }
  }, [
    allSectionFilters,
    specialOffersFilters,
    popularFilters,
    selectedSection,
    selectedCategory,
    refetchProducts,
    refetchSpecialProducts,
    refetchPopularProducts,
    brand,
    country?.value,
    currency?.value,
    transformedSearchedBrands,
    previousSection,
    brandSelected,
  ]);

  useEffect(() => {
    if (products?.items) {
      setDisplayedProducts(products.items);
    }
  }, [products]);

  useEffect(() => {
    if (products?.items && selectedSection === "All") {
      setDisplayedProducts(products.items);
    }
  }, [products, selectedSection]);

  useEffect(() => {
    if (specialProducts?.items && selectedSection === "Special Offers") {
      setDisplayedProducts(specialProducts.items);
    }
  }, [specialProducts, selectedSection]);

  useEffect(() => {
    if (popularProducts?.items && selectedSection === "Popular") {
      setDisplayedProducts(popularProducts.items);
    }
  }, [popularProducts, selectedSection]);

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current);
    return () => {
      scrollPositionRef.current = window.scrollY;
    };
  }, []);

  const getOptionLabel = (option: any) => {
    return (
      <div className="flex">
        {option.logoUrl && (
          <img src={option.logoUrl} alt="logo" className="w-7 h-5 ml-1" />
        )}
        <span className="ml-3"> {option?.name}</span>
      </div>
    );
  };


  const handleWishlistAPI = async (
    productId: string,
    wasInWishlist: boolean
  ) => {
    console.log("##wish inside handleWishlistAPI");
    if (wasInWishlist) {
      console.log("##wish wasInWishlist if");
      await removeFromWishlist(productId);
    } else {
      console.log("##wish wasInWishlist else");
      await addToWishList(productId);
    }
  };

  const onAddToFavorite = async (productId: string) => {
    const productListsQueries = [
      ["products", allSectionFilters],
      ["specialProducts", specialOffersFilters],
      ["popularProducts", popularFilters],
    ];

    let wasInWishlist = false;

    productListsQueries.forEach((queryKey) => {
      const data = queryClient.getQueryData<ProductQueryData>(queryKey);
      const foundProduct = data?.items.find((p) => p.id === productId);
      if (foundProduct) {
        wasInWishlist = foundProduct.isInWishlist;
      }
    });

    try {
      if (wasInWishlist) {
        await removeFromWishlist(productId);
      } else {
        await addToWishList(productId);
      }
      productListsQueries.forEach((queryKey) => {
        queryClient.setQueryData<ProductQueryData>(queryKey, (oldData) => {
          if (!oldData) {
            return {
              items: [],
              totalCount: 0,
            };
          }
          return {
            ...oldData,
            items: oldData.items.map((item) =>
              item.id === productId
                ? { ...item, isInWishlist: !wasInWishlist }
                : item
            ),
          };
        });
      });
    } catch (error) {
      console.error("Error updating wishlist status:", error);
      toast.open(
        "Error",
        `Failed to ${wasInWishlist ? "Remove from" : "Add to"} Wishlist`,
        "error",
        5000
      );
    }
  };

  const allSectionProducts: BrandsListResponse =
    products && selectedSection === "All"
      ? products
      : specialProducts && selectedSection === "Special Offers"
      ? specialProducts
      : popularProducts && selectedSection === "Popular"
      ? popularProducts
      : { items: [], totalCount: 0 };

  const isAnyLoading =
    currenciesLoading ||
    countriesLoading ||
    productsLoading ||
    specialProductsLoading ||
    popularProductsLoading;

  const handleBrandSelection = (selectedBrand: Option | null) => {
    setBrandSelected(true);
    setBrand(selectedBrand?.name);
  };

  const sortOption =
    selectedSection === "All"
      ? sortSelected
      : selectedSection === "Special Offers"
      ? specialSortSelected
      : popularSortSelected;
  const setSortOption =
    selectedSection === "All"
      ? setSortSelected
      : selectedSection === "Special Offers"
      ? setSpecialSortSelected
      : setPopularSortSelected;

  const handleSortChange = (newSortOption: Option) => {
    setSortSelected(newSortOption);
    resetPagination();
  };

  const isAllLoading =
    productsLoading &&
    specialProductsLoading &&
    popularProductsLoading &&
    selectedSection === "All";
  const isSpecialOffersLoading =
    specialProductsLoading && selectedSection === "Special Offers";
  const isPopularLoading =
    popularProductsLoading && selectedSection === "Popular";
  const hasSearchedBrands = searchedBrands.length > 0;
  const productList = {
    items:
      displayedProducts.length > 0
        ? displayedProducts
        : allSectionProducts.items,
    totalCount:
      displayedProducts.length > 0
        ? displayedProducts.length
        : allSectionProducts.totalCount,
  };

  useEffect(() => {
    console.log(`##y Selected Section: ${selectedSection}`);
    console.log(`##y Displayed Products: `, displayedProducts);
  }, [selectedSection, displayedProducts, products, specialProducts, popularProducts]);
  
  
  return (
    <>
      <div className="bg-background sticky top-0 z-50">
        <Header
          onNavigateBack={() => setSelectedSection("All")}
          headerTitle={selectedSection === "All" ? "" : selectedSection}
          hasSearchedBrands={hasSearchedBrands}
          setSearchedBrands={setSearchedBrands}
        />
        <MainSearchField
          countries={countries || []}
          currencies={currencies || []}
          selectedBrand={brand}
          selectedCountry={country}
          selectedCurrency={currency}
          setBrand={handleBrandSelection}
          setSearchedBrands={setSearchedBrands}
          setCountry={setCountry}
          setCurrency={setCurrency}
          getOptionLabel={getOptionLabel}
          selectedSection={selectedSection}
          clearFilters={clearFilters}
        />

        <div className="mt-3 -mr-4">
          <CategoriesTab
            selected={selectedCategory}
            setSelected={setSelectedCategory}
          />
        </div>
      </div>
      <div className="bg-background">
        <div className="w-full flex flex-col gap-2 text-center pt-1 bg-background overflow-y-scroll overflow-x-hidden hide-scrollbar">
          <div className="">
            {!filteringData && <Banners />}

            {noProductsFound && !isAnyLoading && (
              <div className="flex justify-center items-center h-[50vh]">
                <div>No matching results</div>
              </div>
            )}

            {isAllLoading ? (
              <div className="grid grid-cols-2 gap-4 w-full">
                {Array.from({ length: 8 }).map((_, idx) => (
                  <ShimmerEffect
                    key={idx}
                    width="100%"
                    height="120px"
                    borderRadius="20px"
                    miniLoaderSizeOne="picture"
                    miniLoaderSizeTwo="medium"
                    miniLoaderSizeThree="small"
                  />
                ))}
              </div>
            ) : transformedSearchedBrands &&
              transformedSearchedBrands.length > 0 &&
              !noProductsFound ? (
              <BrandList
                products={{
                  items: transformedSearchedBrands,
                  totalCount: transformedSearchedBrands.length,
                }}
                onAddToFavorite={onAddToFavorite}
                isHorizontal={false}
                title="All"
                setSelectedSection={setSelectedSection}
                setSortSelected={handleSortChange}
                sortSelected={sortOption}
                productsLoading={productsLoading}
                specialProductsLoading={specialProductsLoading}
                popularProductsLoading={popularProductsLoading}
                filters={allSectionFilters}
                selectedSection={selectedSection}
                section="searched-brands"
                onDisplayedProductsChange={onDisplayedProductsChange}
                // selectedCategory={selectedCategory}
              />
            ) : (
              <div className="ml-1 hide-scrollbar overflow-x-hidden">
                {popularProducts &&
                  popularProducts.items.length > 0 &&
                  searchedBrands.length === 0 &&
                  !filteringData &&
                  !noProductsFound && (
                    <BrandList
                      products={popularProducts}
                      onAddToFavorite={onAddToFavorite}
                      isHorizontal
                      title="Popular"
                      setSelectedSection={setSelectedSection}
                      productsLoading={popularProductsLoading}
                      specialProductsLoading={specialProductsLoading}
                      popularProductsLoading={popularProductsLoading}
                      filters={popularFilters}
                      selectedSection={selectedSection}
                      section="popular"
                      key={`brandlist-Popular`}
                      onDisplayedProductsChange={onDisplayedProductsChange}
                      // selectedCategory={selectedCategory}
                    />
                  )}

                {specialProducts &&
                  specialProducts.items.length > 0 &&
                  searchedBrands.length === 0 &&
                  !filteringData &&
                  !noProductsFound && (
                    <BrandList
                      products={specialProducts}
                      onAddToFavorite={onAddToFavorite}
                      isHorizontal
                      title="Special Offers"
                      setSelectedSection={setSelectedSection}
                      productsLoading={specialProductsLoading}
                      specialProductsLoading={specialProductsLoading}
                      popularProductsLoading={popularProductsLoading}
                      filters={specialOffersFilters}
                      selectedSection={selectedSection}
                      section="special-offers"
                      key={`brandlist-special-offers`}
                      onDisplayedProductsChange={onDisplayedProductsChange}
                      // selectedCategory={selectedCategory}
                    />
                  )}

                {allSectionProducts &&
                  searchedBrands.length === 0 &&
                  !noProductsFound && (
                    <div
                      ref={allSectionRef}
                      className="overflow-x-hidden hide-scrollbar"
                    >
                      <BrandList
                       // products={allSectionProducts} 
                        products={productList}
                        onAddToFavorite={onAddToFavorite}
                        isHorizontal={false}
                        title={selectedSection}
                        setSortSelected={handleSortChange}
                        sortSelected={sortOption}
                        setSelectedSection={setSelectedSection}
                        productsLoading={productsLoading}
                        specialProductsLoading={specialProductsLoading}
                        popularProductsLoading={popularProductsLoading}
                        filters={allSectionFilters}
                        selectedSection={selectedSection}
                        section=""
                        key={`brandlist-all`}
                        isScrollLocked={isScrollLocked}
                        onDisplayedProductsChange={onDisplayedProductsChange}
                        // selectedCategory={selectedCategory}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
