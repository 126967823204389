import { SETTING_TYPE } from "./settingTypes";
import { NavigationCard } from "components/common/navigationCard";
import { Header } from "components/common/header";
import { useState } from "react";
import { Account } from "./account";
import { Defaults } from "./defaults";
import { Preferences } from "./preferences";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { useAuth } from "context/authContext";

export const Settings = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [showMainSettings, setShowMainSettings] = useState(true);
  const [settingContent, setSettingContent] = useState("");

  const renderSettingsContent = () => {
    switch (settingContent) {
      case SETTING_TYPE.Account:
        return isLoggedIn ? <Account /> : null;
      case SETTING_TYPE.Defaults:
        return isLoggedIn ? <Defaults /> : null;
      case SETTING_TYPE.Preferences:
        return <Preferences />;
      default:
        return <></>;
    }
  };

  const getTitle = () => {
    switch (settingContent) {
      case SETTING_TYPE.Account:
        return isLoggedIn ? SETTING_TYPE.Account : "";
      case SETTING_TYPE.Defaults:
        return isLoggedIn ? SETTING_TYPE.Defaults : "";
      case SETTING_TYPE.Preferences:
        return SETTING_TYPE.Preferences;
      default:
        return "";
    }
  };

  const onNavigate = (path: string) => {
    setSettingContent(path);
    setShowMainSettings(path === "");
  };

  const onNavigateBack = () => navigate(routes.profile);

  return (
    <div>
      {showMainSettings ? (
        <>
          <Header
            headerTitle="Settings"
            hideMiniCart
            onNavigateBack={onNavigateBack}
          />
          <div className="px-2">
            {isLoggedIn && (
              <>
                <NavigationCard
                  label="Account"
                  onNavigate={() => onNavigate(SETTING_TYPE.Account)}
                />
                {/* <NavigationCard
                  label="Defaults"
                  onNavigate={() => onNavigate(SETTING_TYPE.Defaults)}
                  className="mt-4"
                /> */}
              </>
            )}
            {/* <NavigationCard
              label="Preferences"
              onNavigate={() => onNavigate(SETTING_TYPE.Preferences)}
              className="mt-4"
            /> */}
          </div>
        </>
      ) : (
        <>
          <Header
            headerTitle={getTitle()}
            hideMiniCart
            onNavigateBack={() => setShowMainSettings(true)}
          />
          {renderSettingsContent()}
        </>
      )}
    </div>
  );
};
