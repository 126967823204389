import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";
import { Brand } from "models/brandDetail";

export const getBrandById = async (brandId: number): Promise<Brand> => {
  const response = await apiService.get<Brand>(
    `${API_DEV_URL}/app/brands/${brandId}`
  );

  return response.data;
};
