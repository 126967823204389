import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";
import { Code } from "models/orderCode";

export const getCardCode = async (id: string): Promise<Code> => {
  const response = await apiService.get<Code>(
    `${API_DEV_URL}/app/order-item-card/${id}`
  );

  return response.data;
};
