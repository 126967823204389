import { ImageWithName } from "./components/imageWithName";
import { NavigationCard } from "components/common/navigationCard";
import { useContext, useEffect, useState } from "react";
import { Modal } from "components/common/modal";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "routes/routes";
import { logout } from "api/logout";
import { getProfile } from "api/getProfile";
import { useAuth } from "context/authContext";
import { User } from "models/User";
import { handleLogin } from "utils/handleLogin";
import { AppContext } from "context/appContext";

export const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [user, setUser] = useState<User | undefined>(undefined);
  const { setCart } = useContext(AppContext);
  const currentDomain = window.location.hostname;

  const fetchProfile = async () => {
    try {
      const profile = await getProfile();
      setUser(profile);
    } catch (error) {
      console.error("Failed to fetch profile", error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchProfile();
    }
  }, [isLoggedIn, location]);

  const handleButtonClick = async () => {
    if (isLoggedIn) {
      setShowLogout(true);
    } else {
      handleLogin();
      setCart({ basketItems: [] });
    }
  };

  const confirmLogout = async () => {
    await logout();
    setShowLogout(false);
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("authCode");
    localStorage.removeItem("selectedCategory");
    localStorage.removeItem("selectedCountry");
    localStorage.removeItem("selectedCurrency");
    setCart({ basketItems: [] });
    setUser(undefined);
  };

  return (
    <div className="w-full h-screen flex flex-col gap-4 text-center pt-1">
      <div className="text-left ml-1 text-lg font-normal text-primary mt-2">
        Profile
      </div>
      
      <div className="-mt-2">
        <ImageWithName name={user?.userName ?? "User"} />
      </div>

      <div className="mt-2">
        <NavigationCard
          label="Wishlist"
          onNavigate={() => navigate(routes.wishlist)}
        />
        {/* <NavigationCard
          label="Inbox"
          onNavigate={() => onNavigate("")}
          count={3}
          className="mt-4"
        /> */}
        <NavigationCard
          label="Settings"
          onNavigate={() => navigate(routes.settings)}
          className="mt-4"
        />
        {/* <NavigationCard
          label="Support chat"
          onNavigate={() => onNavigate("")}
          className="mt-4"
        /> */}
        <NavigationCard
          label="Help"
          onNavigate={() => navigate(routes.help)}
          className="mt-4"
        />
        <NavigationCard
          label="Privacy Policy"
          onNavigate={() => navigate(routes.privacyPolicy)}
          className="mt-4"
        />
        <NavigationCard
          label={isLoggedIn ? "Log out" : "Log in"}
          onNavigate={handleButtonClick}
          className="mt-4"
        />
      </div>

      <Modal
        isOpen={showLogout}
        setIsOpen={setShowLogout}
        title="Are you sure you want to log out?"
        content={
          <div className="py-2 text-xs font-normal text-primary">
            You will not be able to make orders and see order history
          </div>
        }
        onProceed={confirmLogout}
        cancelText="No"
        proceedText="Yes"
      />
    </div>
  );
};
