import apiService from "api/apiServices";
import { API_DEV_URL } from "utils/constants";

export const removeFromWishlist = async (brandId?: string): Promise<any> => {
  const response = await apiService.delete<any>(
    `${API_DEV_URL}/app/user-wishlist-brand`,
    {
      params: { brandId },
    }
  );

  return response.data;
};
