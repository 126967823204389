import apiService from "api/apiServices";
import { BrandCard } from "models/brandCard";
import { API_DEV_URL } from "utils/constants";

export type BrandsListResponse = {
  totalCount: number;
  items: BrandCard[];
};

export type BrandArguments = {
  searchText?: string;
  countryId?: string;
  currencyId?: string;
  categoryIds?: string;
  brandName?: string;
  isDescending?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};

export const getBrandList = async (
  args?: BrandArguments,
  type?: string
): Promise<BrandsListResponse> => {
  const response = await apiService.get<BrandsListResponse>(
    `${API_DEV_URL}/app/brands${type ? `/${type}` : ""}`,
    {
      params: args,
    }
  );
  
  return response.data;
};
