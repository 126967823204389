import apiService from "./apiServices";
import { API_DEV_URL } from "utils/constants";

export const updateBasket = async ({
  itemId,
  quantity,
}: {
  itemId: string;
  quantity: number;
}): Promise<any> => {
  return apiService
    .put(`${API_DEV_URL}/app/basket/item-quantity/${itemId}`, {
      quantity: quantity,
    })
    .then((res: any) => {
      return res;
    });
};
